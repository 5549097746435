<script>
    export default {
        name: "ThePersonalInput",
        props: {
            name: String,
            value: String,
            placeholder: String,
            type: {
                type: String,
                default: "text",
            },
            error: {
                type: String,
                default: "",
            },
        },
    };
</script>

<template>
    <label class="the-personal-input">
        <input
            class="the-personal-input__field"
            :name="name"
            :type="type"
            :class="{ 'input-error': error.length }"
            :value="value"
            :placeholder="placeholder"
            @input="$emit('input', $event.target.value)"
        />
        <span class="the-personal-input__error" v-if="error.length">{{ error }}</span>
    </label>
</template>

<style lang="scss">
    .the-personal-input {
        display: block;
        width: 100%;
    }

    .the-personal-input__field {
        width: 100%;
        padding: 9px 15px;
        color: #40404b;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        background-color: $personal-input-bg;
        border: $personal-border-width solid $personal-input-border-color;
        border-radius: $personal-input-radius;
        transition: border-color 0.2s ease-in;
        &:hover:not(.input-error),
        &:focus:not(.input-error) {
            border-color: $personal-input-border-color--hover;
        }
        &:not(:placeholder-shown):not(.input-error) {
            border-color: $personal-input-border-color--active;
            &:hover {
                border-color: $personal-input-border-color--active;
            }
        }
        &.input-error {
            border-color: $personal-danger-color;
        }
        @include bp($bp-desktop-sm) {
            padding: 14px 15px;
        }
    }

    .the-personal-input__error {
        display: block;
        margin-top: 4px;
        padding-left: 12px;
        color: $personal-danger-color;
        font-size: 12px;
        line-height: 16px;
    }
</style>
