<script>
    import ThePersonalButton from "@/ThePersonal/components/ui/ThePersonalButton";
    import ThePersonalInput from "@/ThePersonal/components/ui/ThePersonalInput";

    export default {
        name: "ThePersonalBonusesCertificate",
        components: { ThePersonalInput, ThePersonalButton },
        props: {
            isOpened: Boolean,
        },
        data() {
            return {
                code: "",
                isSuccess: false,
                error: "",
            };
        },
        computed: {
            isDisabledButton() {
                return this.code.length < 5;
            },
        },
        watch: {
            isOpened(state) {
                if (state) {
                    setTimeout(() => {
                        const input = this.$refs.focusInput.$el.querySelector("input");
                        input.focus();
                    }, 100);
                } else {
                    this.isSuccess = false;
                    this.error = "";
                    this.code = "";
                }
            },
            code() {
                this.error = "";
            },
        },
        methods: {
            submitHandler() {
                if (this.code) {
                    this.$store.profileApi
                        .setProfileGiftCertificate(this.code)
                        .then(({ success }) => {
                            if (success) {
                                this.isSuccess = true;
                                this.$emit("on-activate");
                            } else {
                                this.error = "Неверный номер сертификата";
                            }
                        });
                }
            },
        },
    };
</script>

<template>
    <div class="the-personal-bonuses-certificate">
        <template v-if="isSuccess">
            <div class="the-personal-bonuses-certificate__title">Сертификат активирован</div>
        </template>
        <form v-else class="the-personal-bonuses-certificate__form" @submit.prevent>
            <div class="the-personal-bonuses-certificate__title">
                Активируйте подарочный сертификат, введя код с него в поле ниже
            </div>
            <the-personal-input
                class="the-personal-bonuses-certificate__input"
                type="text"
                placeholder="Введите код сертификата"
                v-model="code"
                :error="error"
                ref="focusInput"
            ></the-personal-input>
            <the-personal-button @click="submitHandler" :disabled="isDisabledButton">
                Активировать
            </the-personal-button>
        </form>
    </div>
</template>

<style lang="scss">
    .the-personal-bonuses-certificate__title {
        margin-bottom: 10px;
        color: #555770;
        font-size: 14px;
        line-height: 22px;
        @include bp($bp-desktop-sm) {
            color: #1c1c28;
            font-size: 16px;
            line-height: 20px;
        }
    }

    .the-personal-bonuses-certificate__input {
        margin-bottom: 20px;
    }
</style>
