<script>
    import ThePersonalTooltip from "@/ThePersonal/components/ui/ThePersonalTooltip";

    export default {
        name: "ThePersonalBonusesCashback",
        components: { ThePersonalTooltip },
        props: {
            account: {
                required: true,
            },
            nextAmount: {
                type: Number,
                required: true,
            },
        },
        computed: {
            maxAmount() {
                return 100000;
            },
            currentAmount() {
                return this.account?.ordersAmount || 0;
            },
            percentAmount() {
                const amount = this.currentAmount / (this.maxAmount / 100);
                return (amount > 100 ? 100 : amount) + "%";
            },
            cashbackPercent() {
                const percents = {
                    1: "2%",
                    2: "5%",
                    3: "7%",
                };
                return percents[this.account?.levelId];
            },
        },
    };
</script>

<template>
    <div class="the-personal-bonuses-cashback">
        <div class="the-personal-bonuses-cashback__header">
            <div class="the-personal-bonuses-cashback__title">
                Кешбэк
                <div class="the-personal-bonuses-cashback__icon">
                    <img src="@/ThePersonal/assets/icons/tooltip-error.svg" alt="" />
                    <div class="the-personal-bonuses-cashback__tooltip">
                        <the-personal-tooltip width="260">
                            Начисление кэшбэка действует на заказы оформленные с августа 2018
                            года.
                        </the-personal-tooltip>
                    </div>
                </div>
            </div>
            <div class="the-personal-bonuses-cashback__percent">{{ cashbackPercent }}</div>
        </div>

        <div class="the-personal-bonuses-cashback__notification">
            Начисление кэшбэка действует на заказы оформленные с августа 2018 года.
        </div>
        <div class="the-personal-bonuses-cashback__progress-title">
            <div class="the-personal-bonuses-cashback__text">
                <div class="the-personal-bonuses-cashback__name">Сумма покупок:</div>
                <div class="the-personal-bonuses-cashback__value">
                    <template v-if="account">{{ currentAmount | formatPrice }} ₽</template>
                    <template v-else>0</template>
                </div>
            </div>
            <div class="the-personal-bonuses-cashback__text" v-if="currentAmount < maxAmount">
                <div class="the-personal-bonuses-cashback__name">Следующий уровень:</div>
                <div class="the-personal-bonuses-cashback__value">
                    {{ nextAmount | formatPrice }} ₽
                </div>
            </div>
        </div>
        <div class="the-personal-bonuses-cashback__progress">
            <div class="the-personal-bonuses-cashback__progressbar">
                <div
                    :style="{ width: percentAmount }"
                    class="the-personal-bonuses-cashback__progressbar-value"
                ></div>
            </div>
            <div class="the-personal-bonuses-cashback__progress-value">
                <span>2%</span>
                <span>5%</span>
                <span>7%</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-personal-bonuses-cashback {
        padding: 15px;
        border: $personal-common-border-full-inner-mobile;
        border-radius: $personal-box-radius--small;
        @include bp($bp-desktop-sm) {
            margin-bottom: 15px;
            padding: 20px;
            border: $personal-common-border-full-inner-desktop;
            border-radius: $personal-box-radius;
            box-shadow: $personal-common-shadow-min;
        }
    }

    .the-personal-bonuses-cashback__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        color: $personal-widget-title-color;
        font-size: 16px;
        font-weight: $personal-widget-title-weight;
        line-height: 20px;
        @include bp($bp-desktop-sm) {
            margin-bottom: 30px;
        }
    }

    .the-personal-bonuses-cashback__title {
        @include bp($bp-desktop-sm) {
            position: relative;
        }
    }

    .the-personal-bonuses-cashback__icon {
        display: none;
        @include bp($bp-desktop-sm) {
            position: absolute;
            top: 50%;
            right: -30px;
            display: block;
            width: 24px;
            height: 24px;
            transform: translateY(-50%);
            &:hover {
                .the-personal-bonuses-cashback__tooltip {
                    display: block;
                }
            }
        }
    }

    .the-personal-bonuses-cashback__tooltip {
        display: none;
    }

    .the-personal-bonuses-cashback__notification {
        margin-bottom: 10px;
        color: #e91e63;
        font-size: 12px;
        line-height: 18px;
        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .the-personal-bonuses-cashback__progress-title {
        margin-bottom: 15px;
        @include bp($bp-desktop-sm) {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }
    }

    .the-personal-bonuses-cashback__text {
        display: flex;
        margin-bottom: 10px;
        @include bp($bp-desktop-sm) {
            margin-bottom: 0;
        }
    }

    .the-personal-bonuses-cashback__name {
        margin-right: 5px;
        color: #8f90a6;
        font-size: 12px;
        line-height: 16px;
        @include bp($bp-desktop-sm) {
            font-size: 16px;
            font-weight: 300;
            line-height: 18px;
        }
    }

    .the-personal-bonuses-cashback__value {
        color: $personal-title-color;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 18px;
        }
    }

    .the-personal-bonuses-cashback__progress-value {
        display: flex;
        color: $personal-title-color;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        span {
            &:first-child {
                width: 31%;
            }
            &:nth-child(2) {
                flex-grow: 1;
            }
        }
        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 18px;
        }
    }

    .the-personal-bonuses-cashback__progressbar {
        position: relative;
        margin-bottom: 5px;
        height: 17px;
        background-color: #f2f2f5;
        border-radius: $personal-half-radius;
        overflow: hidden;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 30%;
            width: 4px;
            background-color: #fff;
        }
        @include bp($bp-desktop-sm) {
            margin-bottom: 15px;
        }
    }

    .the-personal-bonuses-cashback__progressbar-value {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: $personal-progress-fill;
    }
</style>
