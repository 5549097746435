<script>
    export default {
        name: "ThePersonalTooltip",
        props: {
            isError: Boolean,
            positionX: {
                type: String,
                validator: (v) => ["left", "right"].includes(v),
                default: "left",
            },
            positionY: {
                type: String,
                validator: (v) => ["top", "bottom"].includes(v),
                default: "top",
            },
            width: String,
        },
        computed: {
            computeClassNames() {
                return [
                    `the-personal-tooltip--${this.positionX}`,
                    `the-personal-tooltip--${this.positionY}`,
                    `${this.isError ? "the-personal-tooltip--error" : ""}`,
                ].filter(Boolean);
            },
        },
    };
</script>

<template>
    <div
        class="the-personal-tooltip"
        :class="computeClassNames"
        :style="{ width: width + 'px' }"
    >
        <svg
            width="13"
            height="9"
            viewBox="0 0 13 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6.5 9L0.0048101 0.749999L12.9952 0.75L6.5 9Z" fill="white" />
        </svg>
        <slot></slot>
    </div>
</template>

<style lang="scss">
    .the-personal-tooltip {
        @include bp($bp-desktop-sm) {
            position: absolute;
            z-index: 10;
            padding: 10px;
            color: #40404b;
            font-size: 12px;
            font-weight: $personal-notification-weight;
            line-height: 18px;
            text-transform: initial;
            text-align: center;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 0 15px rgba(#c7c9d9, 0.7);
            svg {
                position: absolute;
            }
        }
    }

    .the-personal-tooltip--error {
        color: #e91e63;
    }

    .the-personal-tooltip--top {
        bottom: calc(100% + 12px);
        svg {
            bottom: -8px;
        }
    }

    .the-personal-tooltip--bottom {
        top: calc(100% + 12px);
        svg {
            top: -8px;
            transform: rotate(180deg);
        }
    }

    .the-personal-tooltip--left {
        left: -10px;
        svg {
            left: 15px;
        }
    }

    .the-personal-tooltip--right {
        right: -10px;
        svg {
            right: 15px;
        }
    }
</style>
