<script>
    import { imageUrl } from "@/ThePersonal/utils";
    import ThePersonalButton from "@/ThePersonal/components/ui/ThePersonalButton";
    import ThePersonalTooltip from "@/ThePersonal/components/ui/ThePersonalTooltip";
    import ThePersonalModal from "@/ThePersonal/components/ui/ThePersonalModal";
    import ThePersonalBonusesHistory from "@/ThePersonal/ThePersonalBonusesHistory";
    import ThePersonalBonusesCertificate from "@/ThePersonal/ThePersonalBonusesCertificate";

    export default {
        name: "ThePersonalBonusesBalance",
        components: {
            ThePersonalBonusesCertificate,
            ThePersonalBonusesHistory,
            ThePersonalModal,
            ThePersonalTooltip,
            ThePersonalButton,
        },
        props: {
            account: {
                required: true,
            },
        },
        data() {
            return {
                isOpenedHistoryModal: false,
                isOpenedCertificateModal: false,
            };
        },
        computed: {
            imageUrl() {
                return imageUrl(this.$root.$_nameDev, "bonuses-balance.png");
            },
        },
        methods: {
            activateCertificateHandler() {
                this.$emit("on-activate-certificate");
            },
        },
    };
</script>

<template>
    <div class="the-personal-bonuses-balance">
        <div class="the-personal-bonuses-balance__box">
            <div class="the-personal-bonuses-balance__image">
                <img :src="imageUrl" alt="" />
            </div>
            <div class="the-personal-bonuses-balance__title">
                Баланс
                <div class="the-personal-bonuses-balance__icon">
                    <img src="@/ThePersonal/assets/icons/tooltip-info.svg" alt="" />
                    <div class="the-personal-bonuses-balance__tooltip">
                        <the-personal-tooltip width="160">
                            Баланс мэдкоинов, доступных для списания
                        </the-personal-tooltip>
                    </div>
                </div>
            </div>
            <div class="the-personal-bonuses-balance__text">
                Количество мэдкоинов, доступных для списания
            </div>
            <div class="the-personal-bonuses-balance__amount">
                <template v-if="account">
                    {{ account.balance | formatPrice }}
                </template>
                <template v-else>0</template>
            </div>
        </div>
        <div class="the-personal-bonuses-balance__controls">
            <the-personal-button @click="isOpenedCertificateModal = true">
                Активировать сертификат
            </the-personal-button>
            <the-personal-button variant="v2" @click="isOpenedHistoryModal = true">
                История операций
            </the-personal-button>
        </div>
        <the-personal-modal
            max-width="900"
            :is-opened="isOpenedHistoryModal"
            @on-close="isOpenedHistoryModal = false"
        >
            <template #title>История операций</template>
            <template #content>
                <the-personal-bonuses-history
                    v-if="account"
                    :is-opened="isOpenedHistoryModal"
                    :balance="account.balance"
                ></the-personal-bonuses-history>
            </template>
        </the-personal-modal>
        <the-personal-modal
            class="the-personal-modal-certificate"
            max-width="450"
            :is-opened="isOpenedCertificateModal"
            @on-close="isOpenedCertificateModal = false"
        >
            <template #title>Активация сертификата</template>
            <template #content>
                <the-personal-bonuses-certificate
                    :is-opened="isOpenedCertificateModal"
                    @on-activate="activateCertificateHandler"
                ></the-personal-bonuses-certificate>
            </template>
        </the-personal-modal>
    </div>
</template>

<style lang="scss">
    .the-personal-bonuses-balance {
        .the-personal-modal-certificate {
            .base-popup__content {
                margin-top: 30vh;
                @include bp($bp-desktop-sm) {
                    margin-top: 0;
                }
            }
        }
    }

    .the-personal-bonuses-balance__box {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        min-height: 140px;
        margin-bottom: 10px;
        padding: 15px;
        border: $personal-common-border-full-inner-mobile;
        border-radius: $personal-box-radius--small;
        @include bp($bp-desktop-sm) {
            min-height: 190px;
            margin-bottom: 15px;
            padding: 20px;
            border: $personal-common-border-full-inner-desktop;
            border-radius: $personal-box-radius;
            box-shadow: $personal-common-shadow-min;
        }
    }

    .the-personal-bonuses-balance__image {
        position: absolute;
        width: 120px;
        height: 140px;
        bottom: 0;
        right: 0;
        img {
            width: 100%;
        }
        @include bp($bp-desktop-sm) {
            width: 140px;
            height: auto;
        }
    }

    .the-personal-bonuses-balance__title {
        position: relative;
        margin-bottom: 5px;
        color: $personal-widget-title-color;
        font-size: 16px;
        font-weight: $personal-widget-title-weight;
        line-height: 20px;
    }

    .the-personal-bonuses-balance__icon {
        display: none;
        @include bp($bp-desktop-sm) {
            position: absolute;
            top: 50%;
            right: -30px;
            display: block;
            width: 24px;
            height: 24px;
            transform: translateY(-50%);
            &:hover {
                .the-personal-bonuses-balance__tooltip {
                    display: block;
                }
            }
        }
    }

    .the-personal-bonuses-balance__tooltip {
        display: none;
    }

    .the-personal-bonuses-balance__text {
        max-width: 150px;
        color: #8f90a6;
        font-size: 12px;
        line-height: 16px;
        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .the-personal-bonuses-balance__amount {
        position: relative;
        margin-top: auto;
        color: #40404b;
        font-size: 29px;
        font-weight: 700;
        line-height: 30px;
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: -45px;
            width: 48px;
            height: 48px;
            background: url("~@/assets/images/profile/bonuses-madcoin.png");
            background-size: auto 48px;
            animation: rotate-madcoin 0.8s steps(19) infinite;
            transform: translateY(-50%) scale(calc(34 / 48));
        }
    }

    .the-personal-bonuses-balance__controls {
        button {
            &:first-child {
                margin-bottom: 10px;
                @include bp($bp-desktop-sm) {
                    margin-bottom: 15px;
                }
            }
        }
    }

    @keyframes rotate-madcoin {
        from {
            background-position: 0 0;
        }
        to {
            background-position: -912px 0;
        }
    }
</style>
