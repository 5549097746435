const defaultPages = {
    payment: "Payment",
    delivery: "Payment",
    guarantee: "Guarantee",
    store: "Store",
    madcoins: "/madcoins/",
};

const getDefaultLinks = (siteName, domain = "ru") => {
    return {
        status: `https://${siteName}.${domain}/?modal=status-order`,
        warranty: `https://${siteName}.${domain}/p-garantiya`,
        info: `support@${siteName}.${domain}`,
        service: `support@${siteName}.${domain}`,
        invoice: `support@${siteName}.${domain}`,
    };
};

export const settings = {
    "xd-design": {
        pages: {
            ...defaultPages,
        },
        links: {
            whatsapp: "https://api.whatsapp.com/send?phone=79999995521",
            telegram: "https://t.me/XdDesignBot",
            ...getDefaultLinks("xd-design"),
        },
    },
    picooc: {
        pages: {
            ...defaultPages,
            faq: "Faq",
        },
        links: {
            whatsapp: "https://api.whatsapp.com/send?phone=79999995512",
            telegram: "tg://resolve?domain=picoocru_bot",
            ...getDefaultLinks("picooc"),
        },
    },
    petoneer: {
        pages: {
            ...defaultPages,
            faq: "Faq",
        },
        links: {
            whatsapp: "https://api.whatsapp.com/send?phone=79161307403",
            telegram: "tg://resolve?domain=petoneer_bot",
            ...getDefaultLinks("petoneer"),
        },
    },
    "mi-ku": {
        pages: {
            ...defaultPages,
        },
        links: {
            whatsapp: "https://api.whatsapp.com/send?phone=79161307403",
            telegram: "tg://resolve?domain=MadrobotsBot",
            ...getDefaultLinks("miku-russia"),
        },
    },
    ledger: {
        pages: {
            ...defaultPages,
            faq: "Faq",
        },
        links: {
            whatsapp: "https://api.whatsapp.com/send?phone=79161307403",
            telegram: "https://t.me/Ledgerwallet_bot",
            ...getDefaultLinks("ledgerwalletrussia"),
        },
    },
    bivan: {
        pages: {
            ...defaultPages,
        },
        links: {
            whatsapp: "https://api.whatsapp.com/send?phone=79161307403",
            telegram: "https://t.me/BivanruBot",
            ...getDefaultLinks("bivan"),
        },
    },
    chipolo: {
        pages: {
            ...defaultPages,
            faq: "Faq",
        },
        links: {
            whatsapp: "https://api.whatsapp.com/send?phone=79161307403",
            telegram: "https://t.me/Ledgerwallet_bot",
            ...getDefaultLinks("ledgerwalletrussia"),
        },
    },
    zavtra: {
        pages: {
            ...defaultPages,
        },
        links: {
            whatsapp: "https://api.whatsapp.com/send?phone=79161307403",
            telegram: "https://t.me/Zavtraco_bot",
            ...getDefaultLinks("zavtra", "co"),
        },
    },
};
