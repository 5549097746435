<script>
    import { scrollState } from "@/utils";

    export default {
        name: "ThePersonalModal",
        props: {
            isOpened: Boolean,
            maxWidth: {
                type: String,
                default: "400",
            },
        },
        watch: {
            isOpened(status) {
                status ? this.show() : this.close();
            },
        },
        mounted() {
            window.addEventListener("popstate", () => {
                this.close();
            });
        },
        updated() {
            this.$refs.confirmDeleteClose.focus();
        },
        beforeDestroy() {
            window.removeEventListener("popstate ", () => {});
        },
        methods: {
            show() {
                window.addEventListener("keyup", this.onEscapeKeyUp);
                scrollState.disable();
            },
            close() {
                this.$emit("on-close");
                window.removeEventListener("keyup", this.onEscapeKeyUp);
                scrollState.enable();
            },
            confirm() {
                this.$emit("on-confirm");
            },
            onEscapeKeyUp({ key }) {
                if (key === "Escape") {
                    this.close();
                }
            },
        },
    };
</script>

<template>
    <transition name="fade">
        <div v-show="isOpened" class="base-popup-overlay">
            <div class="base-popup-container" @click.self="close">
                <div class="base-popup" :style="{ maxWidth: maxWidth + 'px' }">
                    <button
                        class="base-popup__close"
                        @click="close"
                        ref="confirmDeleteClose"
                    ></button>
                    <div class="base-popup__content">
                        <div class="base-popup__title">
                            <slot name="title"></slot>
                        </div>
                        <slot name="content"></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
    .base-popup-overlay {
        --transition-duration: 0.25s;
        position: fixed;
        z-index: 2101;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(#000000, 0.7);
    }

    .base-popup-container {
        width: 100%;
        height: 100%;
        outline: none;
        overflow-y: auto;
        box-sizing: border-box;
        @include bp($bp-desktop-sm) {
            padding: 10px;
        }
    }

    .base-popup {
        position: relative;
        z-index: 20;
        min-height: 100%;
        padding: 15px;
        background-color: #fff;
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
        @include bp($bp-desktop-sm) {
            min-height: auto;
            margin: 20vh auto 10px;
            padding: 40px 25px 30px;
            border-radius: $personal-box-radius;
            &::before {
                content: "";
                @include center-absolute(-10px);
                z-index: -1;
            }
        }
    }

    .base-popup__close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 30px;
        height: 30px;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 2px;
            height: 22px;
            margin: auto;
            background-color: #1c1c28;
            border-radius: 2px;
        }
        &::before {
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
        @include bp($bp-desktop-sm) {
            top: 25px;
            right: 25px;
        }
    }

    .base-popup__title {
        margin-bottom: 10px;
        padding-right: 40px;
        color: $personal-modal-title-color;
        font-size: 18px;
        font-weight: $personal-modal-title-weight;
        line-height: 22px;
        @include bp($bp-tablet-md) {
            font-size: $personal-modal-title-size;
            line-height: $personal-modal-title-height;
        }
    }
</style>
