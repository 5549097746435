<script>
    export default {
        name: "ThePersonalBonusesHistory",
        props: {
            balance: {
                type: Number,
                required: true,
            },
            isOpened: Boolean,
        },
        data() {
            return {
                transactions: [],
                isLoaded: false,
            };
        },
        watch: {
            isOpened(state) {
                if (state) {
                    if (!this.transactions.length) {
                        this.getProfileLoyaltyTransactions();
                    }
                }
            },
        },
        methods: {
            getProfileLoyaltyTransactions() {
                this.$store.profileApi.getProfileLoyaltyTransactions().then((response) => {
                    this.transactions = response;
                    this.isLoaded = true;
                });
            },
            balanceSign(type) {
                return type === "income" ? "+" : "-";
            },
            balanceChangeText(transaction) {
                if (transaction.giftCard) {
                    return `Зачисление подарочной карты на счет ${transaction.giftCard.coupon}`;
                }
                if (transaction.orderId && transaction.accrualDate) {
                    const prefix =
                        transaction.type === "income"
                            ? "Кешбэк с заказа №"
                            : "Списание по заказу №";
                    return prefix + transaction.orderId;
                }
                if (!transaction.accrualDate) {
                    return `Кешбэк с заказа №${transaction.orderId}<br>Можно воспользоваться спустя 14 дней с момента выполнения заказа`;
                }
                if (transaction.description) {
                    return transaction.description;
                }
            },
        },
    };
</script>

<template>
    <div class="the-personal-bonuses-history">
        <div class="the-personal-bonuses-history__title">
            Доступно мэдкоинов: <b>{{ balance | formatPrice }}</b>
        </div>
        <div
            class="the-personal-bonuses-history__table"
            v-if="transactions.length && isLoaded"
        >
            <div class="the-personal-bonuses-history__head">
                <div class="the-personal-bonuses-history__cell">Дата</div>
                <div class="the-personal-bonuses-history__cell">Действие</div>
                <div class="the-personal-bonuses-history__cell">Мэдкоины</div>
            </div>
            <div
                class="the-personal-bonuses-history__row"
                v-for="transaction in transactions"
                :key="transaction.id"
            >
                <div class="the-personal-bonuses-history__cell">
                    <span class="desktop-hidden">Добавлено</span>
                    <span>{{ transaction.date }}</span>
                </div>
                <div class="the-personal-bonuses-history__cell">
                    <span class="desktop-hidden">Действие</span>
                    <span v-html="balanceChangeText(transaction)"></span>
                </div>
                <div class="the-personal-bonuses-history__cell">
                    <span class="desktop-hidden">Мэдкоины</span>
                    <span class="the-personal-bonuses-history__madcoins">
                        {{ balanceSign(transaction.type)
                        }}{{ transaction.amount | formatPrice }}
                    </span>
                </div>
            </div>
        </div>

        <div
            class="the-personal-bonuses-history__table"
            v-if="!transactions.length && !isLoaded"
        >
            <div class="the-personal-bonuses-history__row" v-for="item in 6" :key="item">
                <div
                    class="the-personal-bonuses-history__skeleton the-personal-skeleton"
                ></div>
            </div>
        </div>

        <div
            class="the-personal-bonuses-history__empty"
            v-if="!transactions.length && isLoaded"
        >
            Операции отсутствуют
        </div>
    </div>
</template>

<style lang="scss">
    .the-personal-bonuses-history__title {
        display: inline-block;
        margin-bottom: 15px;
        color: #40404b;
        font-size: 14px;
        line-height: 22px;
        &::after {
            right: -36px;
        }
        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .the-personal-bonuses-history__table {
        padding: 0 15px;
        border: 1px solid $personal-divider-fill;
        border-radius: $personal-box-radius--small;
        @include bp($bp-desktop-sm) {
            padding: 5px 15px 10px;
        }
    }

    .the-personal-bonuses-history__head,
    .the-personal-bonuses-history__row {
        color: #555770;
        font-size: 16px;
        line-height: 18px;
        @include bp($bp-desktop-sm) {
            display: flex;
            align-items: flex-start;
            .the-personal-bonuses-history__cell {
                &:first-child {
                    flex-grow: 1;
                    max-width: 190px;
                }
                &:nth-child(2) {
                    flex-grow: 1;
                    max-width: 540px;
                }
            }
        }
    }

    .the-personal-bonuses-history__head {
        display: none;
        font-weight: 300;
        border-color: $personal-divider-fill;
        border-width: 0 0 1px 0;
        border-style: solid;
        border-image: var(--border-image-gradient);
        border-image-slice: 1;

        @include bp($bp-desktop-sm) {
            display: flex;
            padding: 15px 0;
        }
    }

    .the-personal-bonuses-history__row {
        font-weight: 400;
        padding: 13px 0;
        &:not(:last-child) {
            border-color: $personal-divider-fill;
            border-width: 0 0 1px 0;
            border-style: solid;
            border-image: var(--border-image-gradient);
            border-image-slice: 1;
        }
    }

    .the-personal-bonuses-history__cell {
        display: flex;
        margin-bottom: 8px;
        span {
            line-height: 22px;
            &:first-child {
                width: 80px;
                font-size: 14px;
            }
            &:last-child {
                flex-grow: 1;
                text-align: right;
            }
        }
        @include bp($bp-desktop-sm) {
            margin-bottom: 0;
            span {
                &:first-child {
                    width: auto;
                }
                &:last-child {
                    flex-grow: 0;
                    text-align: left;
                }
            }
        }
    }

    .the-personal-bonuses-history__madcoins {
        padding-right: 22px;
        color: #1c1c28;
        font-size: 16px;
        font-weight: 700;
        &::after {
            right: -15px;
        }
        @include bp($bp-desktop-sm) {
            padding-right: 0;
            &::after {
                right: -36px;
            }
        }
    }

    .the-personal-bonuses-history__title,
    .the-personal-bonuses-history__madcoins {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            width: 48px;
            height: 48px;
            background: url("~@/assets/images/profile/bonuses-madcoin.png");
            background-size: auto 48px;
            transform: translateY(-50%) scale(calc(23 / 48));
        }
    }

    .the-personal-bonuses-history__empty {
        padding: 15px;
        color: #555770;
        border: 1px solid #ebebf0;
        border-radius: $personal-box-radius--small;
    }

    .the-personal-bonuses-history__skeleton {
        width: 100%;
        height: 120px;
        background-color: #e5e5ec;
        border-radius: $personal-box-radius--small;
        @include bp($bp-desktop-sm) {
            height: 20px;
            border-radius: 3px;
        }
    }
</style>
