<script>
    export default {
        name: "ThePersonalButton",
        props: {
            variant: {
                type: String,
                validator: (value) => {
                    return ["v1", "v2"].includes(value);
                },
                default: "v1",
            },
            disabled: Boolean,
        },
        computed: {
            componentClassNames() {
                return [this.variant ? `the-personal-button--${this.variant}` : ""].filter(
                    Boolean
                );
            },
        },
        methods: {
            clickHandler() {
                this.$emit("click");
            },
        },
    };
</script>

<template>
    <button
        class="the-personal-button outline"
        :class="componentClassNames"
        :disabled="disabled"
        @click="clickHandler"
    >
        <slot></slot>
    </button>
</template>

<style lang="scss">
    @import "style";
</style>
