<script>
    import { imageUrl } from "@/ThePersonal/utils";

    export default {
        name: "ThePersonalBonusesLoyalty",
        props: {
            sitesList: {
                type: Array,
                required: true,
            },
        },
        computed: {
            imageUrl() {
                return imageUrl(this.$root.$_nameDev, "bonuses-loyalty.png");
            },
        },
    };
</script>

<template>
    <div class="the-personal-bonuses-loyalty">
        <div class="the-personal-bonuses-loyalty__image">
            <img :src="imageUrl" alt="" />
        </div>
        <div class="the-personal-bonuses-loyalty__title">
            Программа лояльности действует на сайтах:
        </div>
        <ul class="the-personal-bonuses-loyalty__list">
            <li
                class="the-personal-bonuses-loyalty__item"
                v-for="(site, index) in sitesList"
                :key="index"
            >
                <a :href="site.url" class="the-personal-bonuses-loyalty__link" target="_blank">
                    {{ site.name }}
                </a>
            </li>
        </ul>
    </div>
</template>

<style lang="scss">
    .the-personal-bonuses-loyalty {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 185px;
        padding: 15px;
        border: $personal-common-border-full-inner-mobile;
        border-radius: $personal-box-radius--small;
        @include bp($bp-desktop-sm) {
            min-height: 135px;
            margin-bottom: 15px;
            padding: 20px;
            border: $personal-common-border-full-inner-desktop;
            border-radius: $personal-box-radius;
            box-shadow: $personal-common-shadow-min;
        }
    }

    .the-personal-bonuses-loyalty__image {
        position: absolute;
        width: 130px;
        height: 130px;
        bottom: 0;
        right: 0;
        img {
            width: 100%;
            pointer-events: none;
        }
        @include bp($bp-desktop-sm) {
            width: 135px;
            height: 135px;
        }
    }

    .the-personal-bonuses-loyalty__title {
        margin-bottom: 30px;
        color: $personal-widget-title-color;
        font-size: 16px;
        font-weight: $personal-widget-title-weight;
        line-height: 20px;
    }

    .the-personal-bonuses-loyalty__list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        max-height: 280px;
        margin-top: auto;
        @include bp($bp-desktop-sm) {
            flex-direction: row;
            max-height: 100%;
            padding-right: 100px;
        }
    }

    .the-personal-bonuses-loyalty__item {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        @include bp($bp-desktop-sm) {
            &:not(:last-child) {
                margin-bottom: 0;
                margin-right: 10px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .the-personal-bonuses-loyalty__link {
        color: $personal-widget-link-color;
        font-size: 16px;
        font-weight: $personal-widget-link-weight;
        line-height: 22px;
        text-decoration: underline;
    }
</style>
