<script>
    import { apiUrl } from "@/utils";
    import { settings } from "@/ThePersonal/settings";
    import ThePersonalBonusesBalance from "@/ThePersonal/ThePersonalBonusesBalance";
    import ThePersonalBonusesCashback from "@/ThePersonal/ThePersonalBonusesCashback";
    import ThePersonalBonusesLoyalty from "@/ThePersonal/ThePersonalBonusesLoyalty";

    export default {
        name: "ThePersonalBonuses",
        components: {
            ThePersonalBonusesLoyalty,
            ThePersonalBonusesCashback,
            ThePersonalBonusesBalance,
        },
        data() {
            return {
                loyaltyAccount: null,
            };
        },
        computed: {
            nextAmount() {
                const amounts = {
                    1: 30000,
                    2: 100000,
                    3: 0,
                };
                return amounts[this.loyaltyAccount?.levelId || 1];
            },
            bonusesDescription() {
                return [
                    "Мэдкоин — виртуальная бонусная валюта наших магазинов. <br> 1 мэдкоин = 1 рубль.",
                    "Кешбэк уже с первой покупки",
                    "Оплачивайте мэдкоинами до 100% стоимости заказа.",
                    "Мэдкоины доступны для использования через 14 дней после выполнения заказа.",
                    "Через год после начисления мэдкоины превращаются в тыкву. Успейте потратить бонусы!",
                ];
            },
            loyaltySitesList() {
                return [
                    { name: "madrobots.ru", url: "https://madrobots.ru/" },
                    { name: "xd-design.ru", url: "https://xd-design.ru/" },
                    { name: "picooc.ru", url: "https://picooc.ru/" },
                    { name: "petoneer.ru", url: "https://petoneer.ru/" },
                    { name: "miku-russia.ru", url: "https://miku-russia.ru/" },
                    { name: "chipolo.ru", url: "https://chipolo.ru/" },
                    { name: "bivan.ru", url: "https://bivan.ru/" },
                    {
                        name: "ledgerwalletrussia.ru",
                        url: "https://ledgerwalletrussia.ru/",
                    },
                    { name: "zavtra.co", url: "https://zavtra.co/" },
                    // { name: "travel-blue.ru", url: "https://travel-blue.ru/" },
                    // { name: "халатенце.рф", url: "https://xn--80aapa1ao7aup.xn--p1ai/" },

                    // { name: "nanoleaf.ru.com", url: "https://nanoleaf.ru.com/" },
                ];
            },
            madcoinsPageUrl() {
                return settings[this.$root.$_name].pages.madcoins;
            },
        },
        mounted() {
            this.getProfileLoyaltyAccount();
        },
        methods: {
            apiUrl,
            getProfileLoyaltyAccount() {
                this.$store.profileApi.getProfileLoyaltyAccount().then((response) => {
                    this.loyaltyAccount = response;
                });
            },
        },
    };
</script>

<template>
    <section class="the-personal-bonuses">
        <div class="the-personal-bonuses__header">
            <div class="the-personal-bonuses__title">Бонусная программа</div>
            <div class="the-personal-bonuses__text">
                Получайте бонусы с&nbsp;каждой покупки, повышайте свой процент кешбэка
                и&nbsp;оплачивайте мэдкоинами до&nbsp;100% от&nbsp;суммы заказа
            </div>
        </div>
        <div class="the-personal-bonuses__inner">
            <div class="the-personal-bonuses__balance">
                <the-personal-bonuses-balance
                    :account="loyaltyAccount"
                    @on-activate-certificate="getProfileLoyaltyAccount"
                ></the-personal-bonuses-balance>
            </div>
            <div class="the-personal-bonuses__widgets">
                <div class="the-personal-bonuses__cashback">
                    <the-personal-bonuses-cashback
                        :account="loyaltyAccount"
                        :next-amount="nextAmount"
                    ></the-personal-bonuses-cashback>
                </div>
                <div class="the-personal-bonuses__loyalty">
                    <the-personal-bonuses-loyalty
                        :sites-list="loyaltySitesList"
                    ></the-personal-bonuses-loyalty>
                </div>
            </div>
        </div>
        <div class="the-personal-bonuses__footer">
            <div class="the-personal-bonuses__title">Бонусный счет</div>
            <div class="the-personal-bonuses__text">
                Делая покупки в наших магазинах, вы получаете кешбэк с каждого заказа. Чем
                больше сумма покупок — тем выше процент кешбэка!
            </div>
            <div class="the-personal-bonuses__description">
                <img
                    class="the-personal-bonuses__image desktop-hidden"
                    :src="apiUrl(`/include/the_personal/bonuses-about.png`)"
                    alt=""
                />
                <ul class="the-personal-bonuses__list">
                    <li
                        class="the-personal-bonuses__item"
                        v-for="(text, index) in bonusesDescription"
                        :key="index"
                        v-html="text"
                    ></li>
                </ul>
                <div class="the-personal-bonuses__info mobile-hidden">
                    <img
                        class="the-personal-bonuses__image"
                        :src="apiUrl(`/include/the_personal/bonuses-about.png`)"
                        alt=""
                    />
                    <a
                        v-show="madcoinsPageUrl"
                        :href="madcoinsPageUrl"
                        class="the-personal-button the-personal-button--v1 mobile-hidden"
                        target="_blank"
                    >
                        Узнать больше о мэдкоинах
                    </a>
                </div>
                <a
                    v-show="madcoinsPageUrl"
                    :href="madcoinsPageUrl"
                    class="the-personal-button the-personal-button--v1 desktop-hidden"
                    target="_blank"
                >
                    Узнать больше о мэдкоинах
                </a>
            </div>
        </div>
    </section>
</template>

<style lang="scss">
    .the-personal-bonuses {
        padding: 20px 15px 15px;
        border-radius: $personal-box-radius;
        box-shadow: $personal-common-shadow-min;
        border: $personal-common-border-full;

        @include bp($bp-desktop-sm) {
            padding: 40px;
            box-shadow: $personal-common-shadow-max;
        }
    }

    .the-personal-bonuses__header {
        margin-bottom: 20px;
        @include bp($bp-desktop-sm) {
            margin-bottom: 30px;
        }
    }

    .the-personal-bonuses__title {
        margin-bottom: 5px;
        color: $personal-title-color;
        font-size: 19px;
        font-weight: 700;
        line-height: 30px;
        @include bp($bp-desktop-sm) {
            margin-bottom: 10px;
            font-size: 25px;
            font-weight: $personal-title-weight;
            line-height: 30px;
        }
    }

    .the-personal-bonuses__text {
        color: $personal-subtitle-color;
        font-size: 14px;
        line-height: 22px;
        @include bp($bp-desktop-sm) {
            max-width: 660px;
            font-size: 16px;
            line-height: 20px;
        }
    }

    .the-personal-bonuses__inner {
        padding-bottom: 30px;
        @include bp($bp-desktop-sm) {
            display: flex;
            padding-bottom: 40px;
        }
    }

    .the-personal-bonuses__balance {
        margin-bottom: 10px;
        @include bp($bp-desktop-sm) {
            width: 100%;
            max-width: 285px;
            margin-bottom: 0;
            margin-right: 20px;
        }
    }

    .the-personal-bonuses__widgets {
        @include bp($bp-desktop-sm) {
            width: 100%;
        }
    }

    .the-personal-bonuses__cashback {
        margin-bottom: 10px;
        @include bp($bp-desktop-sm) {
            margin-bottom: 15px;
        }
    }

    .the-personal-bonuses__footer {
        padding-top: 30px;
        border-color: $personal-divider-fill;
        border-width: 1px 0 0 0;
        border-style: solid;
        border-image: var(--border-image-gradient);
        border-image-slice: 1;

        @include bp($bp-desktop-sm) {
            padding-top: 40px;
        }
    }

    .the-personal-bonuses__description {
        @include bp($bp-desktop-sm) {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
        }
    }

    .the-personal-bonuses__info {
        max-width: 400px;
    }

    .the-personal-bonuses__image {
        margin-top: 10px;
        border-radius: $personal-box-radius;
        @include bp($bp-desktop-sm) {
            margin-top: 0;
            margin-bottom: 15px;
        }
    }

    .the-personal-bonuses__list {
        flex-basis: 60%;
        min-width: 300px;
        margin-top: 15px;
        @include bp($bp-desktop-sm) {
            margin-top: 0;
            margin-right: 10px;
        }
    }

    .the-personal-bonuses__item {
        position: relative;
        margin-bottom: 15px;
        padding-left: 15px;
        color: $personal-subtitle-color;
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        &::before {
            content: "";
            position: absolute;
            top: 5px;
            left: 0;
            width: 8px;
            height: 8px;
            background-color: $personal-accent-color;
            border-radius: 50%;
        }
    }
</style>
